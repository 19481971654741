export default {
  colors: {
    main: '#003b5c',
    primary: '#2d83dc',
    mainVariation: '#426ab5',
    sub: '#55b776',
    danger: 'red',
    bg: '#f1f5f9',
    text: '#000',
  },
};
