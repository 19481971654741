import React from 'react';
import REGIONS from '../locations/regions.json';
import whiteLogo from '../img/logo-white.svg';

const Footer = () => (
    <footer className="bg-gray-800" aria-labelledby="footerHeading">
        <div id="footerHeading" className="sr-only">
            Footer
        </div>
        <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
            <div className="lg:grid lg:grid-cols-4 lg:gap-8">
                <div className="space-y-8 lg:col-span-1">
                    <img
                        className="h-10"
                        src={whiteLogo}
                        alt="Logo Lead Advisor"
                    />
                    <p className="text-sm text-gray-400">
                        126 avenue du Général Leclerc
                        <br />
                        92100 Boulogne-Billancourt
                        <br />
                        SAS immatriculée sous le numéro 832 897 904 00013
                        <br />
                        au RCS de Nanterre
                    </p>
                    <div>
                        <div className="pb-4 text-xl font-semibold text-blue-300">
                            Devis immédiat et conseils par un expert en sécurité
                            au
                        </div>
                        <div className="flex sm:flex-row">
                            <div className="flex">
                                <a
                                    href="tel:07 60 48 75 74"
                                    className="flex items-center px-1 text-lg font-semibold text-center text-blue-800 bg-white lg:px-2"
                                >
                                    07 60 48 75 74
                                </a>
                            </div>
                            <div className="px-2 py-2 text-sm font-semibold text-center text-white bg-gray-700">
                                Service gratuit
                                <br />+ prix appel
                            </div>
                        </div>
                        <div>
                            <div className="mt-4 font-semibold text-blue-400">
                                Intervention sous 2 heures
                            </div>
                            <div className="mt-2 font-semibold text-gray-200">
                                7J/7 de 9h00 à 21h00
                            </div>
                        </div>
                    </div>
                    <div className="flex space-x-6">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://twitter.com/lead_advisor"
                            className="text-gray-300 hover:text-gray-200"
                        >
                            <span className="sr-only">Twitter</span>
                            <svg
                                className="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                            >
                                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.linkedin.com/company/lead-advisor/"
                            className="text-gray-300 hover:text-gray-200"
                        >
                            <span className="sr-only">LinkedIn</span>
                            <svg
                                className="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 448 512"
                                aria-hidden="true"
                            >
                                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="mt-12 lg:mt-0">
                    <div className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                        Lead Advisor Security
                    </div>
                    <ul className="mt-4 space-y-4">
                        <li>
                            <a
                                href="/about"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                À propos de Lead Advisor
                            </a>
                        </li>

                        <li>
                            <a
                                href="/articles"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Articles
                            </a>
                        </li>

                        <li>
                            <a
                                href="/pricing"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Tarifs
                            </a>
                        </li>
                        <li>
                            <a
                                href="/partners"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Partenaires
                            </a>
                        </li>
                        <li>
                            <a
                                href="/jobs"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Emploi
                            </a>
                        </li>
                        <li>
                            <a
                                href="/formation"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Formation agent sécurité
                            </a>
                        </li>
                        <li>
                            <div className="my-3">
                                <a
                                    href="/devis-securite"
                                    className="text-base text-gray-200 hover:text-gray-50"
                                >
                                    Devis sécurité
                                </a>
                            </div>
                        </li>
                        <li>
                            <div className="my-3">
                                <a
                                    href="/devis-gardiennage"
                                    className="text-base text-gray-200 hover:text-gray-50"
                                >
                                    Devis gardiennage
                                </a>
                            </div>
                        </li>
                        <li>
                            <a
                                href="/conditions"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Conditions d’utilisation
                            </a>
                        </li>
                        <li>
                            <a
                                href="/site-plan"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Plan du site
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="mt-12 lg:mt-0">
                    <div className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                        Sécurité privée
                    </div>
                    <ul className="mt-4 space-y-4">
                        <li>
                            <a
                                href="/articles/entreprise-de-securite-privee"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Entreprise de sécurité
                            </a>
                        </li>
                        <li>
                            <a
                                href="/articles/boite-de-securite-privee"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Boîte de sécurité
                            </a>
                        </li>
                        <li>
                            <a
                                href="/articles/agent-cynophile"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Agent cynophile
                            </a>
                        </li>
                        <li>
                            <a
                                href="/articles/agent-de-securite-incendie-ssiap"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Agent de sécurité incendie
                            </a>
                        </li>
                        <li>
                            <a
                                href="/articles/agent-de-securite-magasin"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Agent de sécurité magasin
                            </a>
                        </li>
                        <li>
                            <a
                                href="/articles/agent-de-prevention-et-de-securite-aps"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Agent de prévention et de sécurité
                            </a>
                        </li>
                        <li>
                            <a
                                href="/articles/securite-evenementielle"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Sécurité évènementielle
                            </a>
                        </li>
                        <li>
                            <a
                                href="/articles/appels-offres-securite-privee"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Marchés privés sécurité
                            </a>
                        </li>
                        <li>
                            <a
                                href="/articles/entreprise-de-gardiennage-en-ile-de-france"
                                className="text-base text-gray-200 hover:text-gray-50"
                            >
                                Entreprise de gardiennage
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="mt-12 lg:mt-0">
                    <div className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                        Sociétés de sécurité dans votre région
                    </div>
                    <ul className="mt-4 space-y-4">
                        {REGIONS.map((region) => (
                            <li key={region.code}>
                                <a
                                    href={`/articles/agent-de-securite/${region.slug}`}
                                    className="text-base text-gray-200 hover:text-gray-50"
                                >
                                    {region.nom}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="pt-8 mt-12 border-t border-gray-200">
                <p className="text-base text-gray-400 xl:text-center">
                    &copy; {new Date().getFullYear()} Lead Advisor
                </p>
            </div>
        </div>
    </footer>
);

export default Footer;
