import { Transition } from '@tailwindui/react';
import React, { useState } from 'react';
import Footer from '../components/Footer';
import whiteLogo from '../img/logo-white.svg';
import styled, { ThemeProvider } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { createGlobalStyle } from 'styled-components';
import Helmet from 'react-helmet';

import theme from '../theme';

import SourceSansProRegularEOT from '../fonts/source-sans-pro-v11-latin-regular.eot';
import SourceSansProRegularWOFF from '../fonts/source-sans-pro-v11-latin-regular.woff';
import SourceSansProRegularWOFF2 from '../fonts/source-sans-pro-v11-latin-regular.woff2';
import SourceSansProRegularTTF from '../fonts/source-sans-pro-v11-latin-regular.ttf';
import SourceSansProRegularSVG from '../fonts/source-sans-pro-v11-latin-regular.svg';

import SourceSansProSemiBoldEOT from '../fonts/source-sans-pro-v11-latin-600.eot';
import SourceSansProSemiBoldWOFF from '../fonts/source-sans-pro-v11-latin-600.woff';
import SourceSansProSemiBoldWOFF2 from '../fonts/source-sans-pro-v11-latin-600.woff2';
import SourceSansProSemiBoldTTF from '../fonts/source-sans-pro-v11-latin-600.ttf';
import SourceSansProSemiBoldSVG from '../fonts/source-sans-pro-v11-latin-600.svg';

import SourceSansProBoldEOT from '../fonts/source-sans-pro-v11-latin-700.eot';
import SourceSansProBoldWOFF from '../fonts/source-sans-pro-v11-latin-700.woff';
import SourceSansProBoldWOFF2 from '../fonts/source-sans-pro-v11-latin-700.woff2';
import SourceSansProBoldTTF from '../fonts/source-sans-pro-v11-latin-700.ttf';
import SourceSansProBoldSVG from '../fonts/source-sans-pro-v11-latin-700.svg';

const GlobalStyle = createGlobalStyle`
   /* source-sans-pro-regular - latin */
     @font-face {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-display: fallback;
        font-weight: 400;
        src: url(${SourceSansProRegularEOT}); /* IE9 Compat Modes */
        src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
          url(${SourceSansProRegularEOT}?#iefix)
            format('embedded-opentype'),
          /* IE6-IE8 */
            url(${SourceSansProRegularWOFF2})
            format('woff2'),
          /* Super Modern Browsers */
            url(${SourceSansProRegularWOFF})
            format('woff'),
          /* Modern Browsers */
            url(${SourceSansProRegularTTF})
            format('truetype'),
          /* Safari, Android, iOS */
            url(${SourceSansProRegularSVG}#SourceSansPro)
            format('svg'); /* Legacy iOS */
      }
      /* source-sans-pro-600 - latin */
      @font-face {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-display: fallback;
        font-weight: 600;
        src: url(${SourceSansProSemiBoldEOT}); /* IE9 Compat Modes */
        src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
          url(${SourceSansProSemiBoldEOT}?#iefix)
            format('embedded-opentype'),
          /* IE6-IE8 */
            url(${SourceSansProSemiBoldWOFF2})
            format('woff2'),
          /* Super Modern Browsers */
            url(${SourceSansProSemiBoldWOFF})
            format('woff'),
          /* Modern Browsers */
            url(${SourceSansProSemiBoldTTF})
            format('truetype'),
          /* Safari, Android, iOS */
            url(${SourceSansProSemiBoldSVG}#SourceSansPro)
            format('svg'); /* Legacy iOS */
      }
      /* source-sans-pro-700 - latin */
      @font-face {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-display: fallback;
        font-weight: 700;
          src: url(${SourceSansProBoldEOT}); /* IE9 Compat Modes */
        src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
          url(${SourceSansProBoldEOT}?#iefix)
            format('embedded-opentype'),
          /* IE6-IE8 */
            url(${SourceSansProBoldWOFF2})
            format('woff2'),
          /* Super Modern Browsers */
            url(${SourceSansProBoldWOFF})
            format('woff'),
          /* Modern Browsers */
            url(${SourceSansProBoldTTF})
            format('truetype'),
          /* Safari, Android, iOS */
            url(${SourceSansProBoldSVG}#SourceSansPro)
            format('svg'); /* Legacy iOS */
      }
   `;

const CorporateLayout = ({
  children,
  sidebarContent = false,
  rightsidebarContent = false,
}: {
  children: React.ReactNode;
  sidebarContent?: React.ReactNode;
  rightsidebarContent?: React.ReactNode;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isLoggedIn =
    typeof localStorage !== 'undefined'
      ? !!localStorage.getItem('user')
      : false;

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title
              description
              siteUrl
            }
          }
        }
      `}
      render={(data) => (
        <div>
          <GlobalStyle />
          <Helmet>
            <html lang="en" />
            <title>{data.site.siteMetadata.title}</title>
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />

            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/img/favicon.ico"
            />
            <link
              rel="icon"
              type="image/png"
              href="/img/favicon.ico"
              sizes="32x32"
            />
            <link
              rel="icon"
              type="image/png"
              href="/img/favicon.ico"
              sizes="16x16"
            />

            <link
              rel="mask-icon"
              href="/img/safari-pinned-tab.svg"
              color="#ff4400"
            />
            <meta name="theme-color" content="#fff" />

            <meta property="og:type" content="business.business" />
            <meta property="og:title" content={data.site.siteMetadata.title} />
            <meta property="og:url" content="/" />
            <meta
              property="og:image"
              content={`${data.site.siteMetadata.siteUrl}/articles/img/og-image.jpg`}
            />
          </Helmet>
          <div className="relative bg-gray-800">
            <div className="relative pt-4">
              <nav className="relative flex items-center justify-between max-w-screen-xl px-4 mx-auto sm:px-6">
                <div className="flex items-center flex-1">
                  <div className="flex items-center justify-between lg:w-auto">
                    <a href="/">
                      <img
                        className="h-6 sm:h-8 sm:w-28 md:w-32 lg:w-36"
                        src={whiteLogo}
                        alt="Logo Lead Advisor"
                      />
                    </a>

                    <div className="items-center flex-shrink-0 hidden lg:space-x-7 xl:space-x-10 lg:flex lg:ml-10">
                      <a
                        href="/articles"
                        className="text-sm font-semibold text-white transition duration-150 ease-in-out hover:text-gray-300"
                      >
                        Articles
                      </a>
                      <a
                        href="/pricing"
                        className="text-sm font-semibold text-white transition duration-150 ease-in-out hover:text-gray-300"
                      >
                        Tarifs
                      </a>
                      <a
                        href="/partners"
                        className="text-sm font-semibold text-white transition duration-150 ease-in-out hover:text-gray-300"
                      >
                        Partenaires
                      </a>
                      <a
                        href="/jobs"
                        className="text-sm font-semibold text-white transition duration-150 ease-in-out hover:text-gray-300"
                      >
                        Emploi
                      </a>
                      <a
                        href="/about"
                        className="text-sm font-semibold text-white transition duration-150 ease-in-out hover:text-gray-300"
                      >
                        À propos
                      </a>
                    </div>
                  </div>

                  <div className="flex items-center justify-center flex-grow">
                    <span className="inline-flex items-center px-2 py-1 mx-2 font-semibold leading-4 text-center text-green-800 bg-green-100 rounded sm:mx-0 sm:max-w-xxs lg:max-w-xxxs xl:max-w-xxs lg:px-1 xl:px-2 text-xxs sm:text-xs">
                      <span>
                        Devis immédiat et conseils par un expert en sécurité au
                        <span className="text-xs text-blue-700 whitespace-nowrap sm:text-sm">{` 07 60 48 75 74 `}</span>
                      </span>
                    </span>
                    <div>
                      <a
                        href="/providers"
                        className="block px-1 py-1 font-semibold text-center text-indigo-700 transition duration-150 ease-in-out bg-indigo-100 rounded-md sm:ml-2 xl:ml-4 sm:py-2 sm:px-2 text-xxs lg:text-xs hover:bg-indigo-200 hover:text-indigo-800 focus:outline-none focus:bg-gray-100 focus:text-indigo-800"
                        role="menuitem"
                      >
                        Vous êtes prestataire de sécurité ?
                      </a>
                    </div>
                  </div>
                  <div className="flex items-center -mr-2 lg:hidden">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                      id="main-menu"
                      aria-label="Main menu"
                      aria-haspopup="true"
                      onClick={() => setIsMenuOpen(true)}
                    >
                      <svg
                        className="w-6 h-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                {isLoggedIn ? (
                  <div className="hidden lg:flex">
                    <a
                      href="/dashboard"
                      className="inline-flex items-center px-4 py-2 text-sm font-semibold leading-5 text-white transition duration-150 ease-in-out bg-gray-600 border border-transparent rounded-md hover:bg-gray-500 focus:outline-none focus:ring-gray focus:border-gray-700 active:bg-gray-700"
                    >
                      Tableau de bord
                    </a>
                  </div>
                ) : (
                  <div className="hidden lg:flex">
                    <a
                      href="/login?page=login"
                      className="inline-flex items-center px-4 py-2 text-sm font-semibold leading-5 text-white transition duration-150 ease-in-out bg-gray-600 border border-transparent rounded-md hover:bg-gray-500 focus:outline-none focus:ring-gray focus:border-gray-700 active:bg-gray-700"
                    >
                      Connexion
                    </a>
                  </div>
                )}
              </nav>
              <Transition
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                show={isMenuOpen}
              >
                <div className="absolute inset-x-0 top-0 z-20 p-2 transition origin-top-right transform lg:hidden">
                  <div className="z-10 rounded-lg shadow-md">
                    <div
                      className="overflow-hidden bg-white rounded-lg ring-1 ring-black ring-opacity-5"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="main-menu"
                    >
                      <div className="flex items-center justify-between px-5 pt-4">
                        <div>
                          <img
                            className="h-6"
                            src={`/images/logo-new.svg`}
                            alt="Logo Lead Advisor"
                          />
                        </div>
                        <div className="-mr-2">
                          <button
                            type="button"
                            className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                            aria-label="Close menu"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <svg
                              className="w-6 h-6"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="px-2 pt-2 pb-3 space-y-1">
                        <a
                          href="/articles"
                          className="block px-3 py-2 text-base font-semibold text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                          role="menuitem"
                        >
                          Articles
                        </a>
                        <a
                          href="/pricing"
                          className="block px-3 py-2 text-base font-semibold text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                          role="menuitem"
                        >
                          Partenaires
                        </a>
                        <a
                          href="/partners"
                          className="block px-3 py-2 text-base font-semibold text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                          role="menuitem"
                        >
                          Emploi
                        </a>
                        <a
                          href="/jobs"
                          className="block px-3 py-2 text-base font-semibold text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                          role="menuitem"
                        >
                          Tarifs
                        </a>
                        <a
                          href="/about"
                          className="block px-3 py-2 text-base font-semibold text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                          role="menuitem"
                        >
                          À propos
                        </a>
                      </div>
                      <div>
                        {isLoggedIn ? (
                          <a
                            href="/dashboard"
                            className="block w-full px-5 py-3 font-semibold text-center text-indigo-600 transition duration-150 ease-in-out bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700"
                            role="menuitem"
                          >
                            Tableau de bord
                          </a>
                        ) : (
                          <a
                            href="/login?page=login"
                            className="block w-full px-5 py-3 font-semibold text-center text-indigo-600 transition duration-150 ease-in-out bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700"
                            role="menuitem"
                          >
                            Connexion
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
              <ThemeProvider theme={theme}>
                <div className="relative">
                  <main className="mt-4 bg-gray-50">
                    {!!sidebarContent || rightsidebarContent ? (
                      <div className="relative flex flex-col xl:grid xl:grid-cols-12">
                        {!!sidebarContent ? (
                          <div className="hidden col-span-3 xxxl:col-span-2 xl:block">
                            <div className="sticky top-0 bottom-0 flex-shrink-0 min-h-screen bg-white border-b border-r border-gray-200 shadow-md ">
                              <div className="h-full py-6 pr-6">
                                <div className="h-full">
                                  <div className="pl-6 pr-6">
                                    {sidebarContent}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="hidden col-span-3 xxxl:col-span-2 xl:block"></div>
                        )}
                        <div
                          className={`relative h-full col-span-12 xl:col-span-7 xxxl:col-span-8 ${
                            !!sidebarContent ? 'xl:-ml-28' : ''
                          }`}
                        >
                          {!!rightsidebarContent ? (
                            <div className="sticky top-0 z-10 block -mt-12 xl:hidden">
                              {rightsidebarContent}
                            </div>
                          ) : (
                            false
                          )}
                          <div className="px-4 pt-8 pb-20 xl:pt-12 sm:px-6 lg:pb-28 lg:px-8">
                            <div className="mx-auto">{children}</div>
                          </div>
                        </div>
                        {!!rightsidebarContent ? (
                          <div className="hidden xl:block xl:col-span-2">
                            <div className="sticky top-0 z-10">
                              {rightsidebarContent}
                            </div>
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    ) : (
                      <div className="relative h-full">
                        {!!rightsidebarContent ? (
                          <div className="block xl:hidden">
                            {rightsidebarContent}
                          </div>
                        ) : null}
                        <div className="px-4 pt-8 pb-20 xl:pt-12 sm:px-6 lg:pb-28 lg:px-8">
                          <div className="mx-auto">{children}</div>
                        </div>
                      </div>
                    )}
                  </main>
                </div>
              </ThemeProvider>
              <div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default CorporateLayout;
